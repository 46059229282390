import { FunctionComponent } from "react";
import * as React from "react";

type Props = {
  isActive: boolean;
  close: any;
};

const Modal: FunctionComponent<Props> = ({ isActive, children, close }) => {
  return (
    <div className={isActive ? "modal is-active is-clipped" : "modal is-clipped"}>
      <div className="modal-background" />
      <div className="modal-content">{children}</div>
      <button className="modal-close is-large" onClick={() => close()} aria-label="close" />
    </div>
  );
};

export default Modal;
