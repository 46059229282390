import { combineReducers } from "redux";

import { cart } from "./CartReducer";
import { data } from "./DataReducer";
import { general } from "./GeneralReducer";

export type GetOutt = {
  cart: {};
  data: {};
  gener: {};
};

export default combineReducers({
  cart,
  data,
  general,
});
