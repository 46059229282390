import * as React from "react";
import { Employee } from "../Data";
import "./TeamList.scss";
import { Link } from "@reach/router";

export default function TeamList({ team, employeeId }: { team: Employee[]; employeeId?: string }) {
  return (
    <ul className="columns is-multiline is-mobile">
      {team.map(employee => {
        const classNames = ["employee column is-6 has-text-centered"];

        if (employeeId && employeeId === employee.id) {
          classNames.push("is-selected");
        }

        return (
          <li key={employee.id} className={classNames.join(" ")}>
            <Link to={`/over-ons/team/?id=${employee.id}`}>
              <figure className="image is-64x64 is-inline-block">
                <img src={employee.avatar} alt={employee.name} />
              </figure>
              <p>{employee.name}</p>
            </Link>
          </li>
        );
      })}
    </ul>
  );
}
