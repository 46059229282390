import { Product } from "../Data";

const TOGGLE_CART = "TOGGLE_CART";
export function toggleCart() {
  return {
    type: TOGGLE_CART,
  };
}

type State = {
  products: Product[];
  showCart: boolean;
};

export const general = (
  state: State = {
    showCart: false,
    products: [],
  },
  action: any
) => {
  switch (action.type) {
    case TOGGLE_CART:
      return {
        ...state,
        showCart: !state.showCart,
      };

    default:
      return state;
  }
};
