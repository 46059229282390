import * as React from "react";
import "./SlideShow.scss";
import { Component } from "react";

import previous from './img/previous.png';
import next from './img/next.png';

type SlideshowProps = {
  imageUrls: string[];
};

type SlideshowState = {
  index: number;
};

class SlideShow extends Component<SlideshowProps, SlideshowState> {
  state: SlideshowState = {
    index: 0,
  };

  next(){
    this.setState({
      index: this.state.index + 1 < this.props.imageUrls.length ? (this.state.index + 1) : 0
    })
  }

  previous(){
    this.setState({
      index: this.state.index > 0 ? (this.state.index - 1) : (this.props.imageUrls.length - 1)
    })
  }

  render() {
    if (!this.props.imageUrls[this.state.index]) {
      return null;
    }

    const imageUrl = this.props.imageUrls[this.state.index];

    return (
      <figure className="image slideshow">
        { (this.props.imageUrls.length > 1) && <img className="arrow left" src={previous} onClick={()=>this.previous()} alt="Previous" /> }
        <img src={imageUrl} alt="" />
        { (this.props.imageUrls.length > 1) && <img className="arrow right" src={next} onClick={()=>this.next()} alt="Next" /> }
      </figure>
    );
  }
}
export default SlideShow;
