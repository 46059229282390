import * as React from "react";
import "./Rent.scss";
import { Component } from "react";
import { RouteComponentProps, Link } from "@reach/router";
import { Data, Product, Service, RentPrice } from "./Data";
import Page from "./Page";

import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { DateRangePicker } from "react-dates";
import { Moment } from "moment";
import moment from "moment";
import Modal from "./Modal";

import "moment/locale/nl-be";
import { finishRent } from "./Api";

type Props = {
  data: Data;
  productId?: string;
};

type State = {
  products: Product[];
  services: Service[];
  step: number;
  start: Moment | null;
  end: Moment | null;
  postal: string;
  focusedInput: any;
  selectedServices: Service[];
  email: string;
  firstName: string;
  lastName: string;
  coupon: string;
  delivery: string;
  showConfirmationModal: boolean;
};

class Rent extends Component<Props & RouteComponentProps, State> {
  state: State = {
    products: [],
    services: [],
    step: 0,
    start: null,
    end: null,
    postal: "",
    selectedServices: [],
    email: "",
    firstName: "",
    lastName: "",
    coupon: "",
    delivery: "Leveren",
    focusedInput: "",
    showConfirmationModal: false,
  };

  async componentDidMount() {
    const data = await Promise.all([await this.props.data.getProducts(), await this.props.data.getServices()]);
    const [products, services] = data;
    this.setState({ products, services });
  }

  calculateRentPrice(rentprices: RentPrice[]) {
    let { start, end } = this.state;
    var rentCost = 0;
    var newEnd = end ? moment(end.toString()) : null;

    // calculate rentprice
    if (start && newEnd && rentprices) {
      // extract max amount of weeks from period
      const numberOfWeeks = Math.floor(newEnd.diff(start, "days") / 7);
      newEnd = newEnd.subtract(7 * numberOfWeeks, "days");

      // extract weekend from period
      const numberOfWeekends = 0;

      // count days left
      const numberOfDays = newEnd.diff(start, "days");

      const weekPrice = rentprices.find(rentprice => rentprice.label === "Week");
      const weekendPrice = rentprices.find(rentprice => rentprice.label === "Weekend");
      const weekdagPrice = rentprices.find(rentprice => rentprice.label === "Weekdag");

      if (weekPrice !== undefined) {
        rentCost += numberOfWeeks * weekPrice.price;
      }

      if (weekendPrice !== undefined) {
        rentCost += numberOfWeekends * weekendPrice.price;
      }

      if (weekdagPrice !== undefined) {
        rentCost += numberOfDays * weekdagPrice.price;
      }
    }

    return rentCost;
  }

  calculateServicesPrice() {
    let { selectedServices } = this.state;
    var servicesCost = 0;

    // calculate cost of chosen services
    selectedServices.forEach(service => {
      if (service.amount) {
        servicesCost += service.price * service.amount;
      } else {
        servicesCost += service.price;
      }
    });

    return servicesCost;
  }

  calculateTotalPrice(rentprices: RentPrice[]) {
    return this.calculateRentPrice(rentprices) + this.calculateServicesPrice() + ",- €";
  }

  isValidEmail(email: string) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  renderStep1() {
    const { productId } = this.props;
    const { step, products } = this.state;
    const product = products.find(product => product.id === productId);
    if (product) {
      const { rentprices, delivery } = product;
      return (
        <div className="rent-content start">
          <div>
            <h3 className="title is-4">Prijzen (excl. BTW)</h3>
            <ul>
              {rentprices.map(rentprice => {
                return (
                  <li key={rentprice.label}>
                    <span>{rentprice.label}</span>
                    <span>{rentprice.price},- €</span>
                  </li>
                );
              })}
            </ul>
          </div>
          {delivery && (
            <div>
              <h3 className="title is-4" style={{ marginTop: "20px" }}>
                Levering of ophaling
              </h3>
              <p>{delivery}</p>
            </div>
          )}
          <button
            className="button is-primary"
            onClick={() => {
              this.setState({
                step: step + 1,
              });
            }}
          >
            Volgende Stap - Aanvullende Informatie
          </button>
        </div>
      );
    }
  }

  renderStep2() {
    const { step, services, postal, delivery } = this.state;
    let { selectedServices } = this.state;

    return (
      <div className="rent-content services">
        <h3 className="title is-4">Aanvullende Informatie</h3>
        <div>
          <div className="field">
            <label className="label" htmlFor="">
              Gewenste diensten
            </label>
            <div className="control">
              <ul>
                {services.map((service, index) => {
                  return (
                    <li key={index}>
                      <div className="up">
                        <input
                          type="checkbox"
                          onChange={e => {
                            if (e.target.checked) {
                              selectedServices.push(service);
                            } else {
                              selectedServices = selectedServices.filter(obj => obj !== service);
                            }

                            this.setState({
                              selectedServices,
                            });
                          }}
                        />
                        <span>{service.label}</span>
                        {service.multiple && selectedServices.find(s => service.id === s.id) && (
                          <span style={{ marginLeft: "auto", marginRight: "10px" }}>Aantal: </span>
                        )}
                        {service.multiple && selectedServices.find(s => service.id === s.id) && (
                          <input
                            type="number"
                            className="input"
                            defaultValue={"1"}
                            onChange={e => {
                              selectedServices[index].amount = Number(e.target.value);
                              this.setState({
                                selectedServices,
                              });
                            }}
                          />
                        )}
                      </div>
                      <div>
                        {false && <span className="service-info">Hier komt info over een service</span>}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "30px",
            marginBottom: "10px",
          }}
        >
          <div
            className="field"
            style={{
              marginRight: "20px",
            }}
          >
            <label className="label" htmlFor="delivery-selector">
              Leveringsinformatie
            </label>
            <div className="control">
              <div className="select">
                <select
                  id="delivery-selector"
                  value={delivery}
                  onChange={e => this.setState({ delivery: e.target.value })}
                >
                  <option value="Leveren">Leveren</option>
                  <option value="Ophalen">Ophalen</option>
                </select>
              </div>
            </div>
          </div>
          {delivery === "Leveren" && (
            <div className="field">
              <label className="label" htmlFor="delivery-selector">
                Postcode
              </label>
              <div className="control">
                <input
                  type="text"
                  value={postal}
                  ref="postal"
                  className="input"
                  onChange={e => this.setState({ postal: e.target.value })}
                />
              </div>
            </div>
          )}
        </div>
        <button
          className="button is-light"
          style={{
            float: "left",
          }}
          onClick={() => {
            this.setState({
              step: step - 1,
            });
          }}
        >
          Vorige Stap
        </button>
        <button
          className="button is-primary"
          onClick={() => {
            if ((postal && postal !== "") || delivery === "Ophalen") {
              this.setState({
                step: step + 1,
              });
            } else {
              alert("Gelieve een geldige postcode in te geven.");
            }
          }}
        >
          Volgende Stap - Periode Selecteren
        </button>
      </div>
    );
  }

  getRangeOfDates(start: Moment, end: Moment, arr = [start.startOf("day")]): Moment[] {
    if (start.isAfter(end)) throw new Error("start must precede end");

    const next = moment(start)
      .add(1, "day")
      .startOf("day");

    if (next.isAfter(end, "day")) return arr;

    return this.getRangeOfDates(next, end, arr.concat(next));
  }

  renderStep3(product: Product) {
    const { start, end, focusedInput, step } = this.state;

    var blockedDays: any = [];
    for (let un of product.unavailabilities) {
      blockedDays.push(...this.getRangeOfDates(moment(un.start), moment(un.end)));
    }

    return (
      <div className="rent-content dates">
        <h3 className="title is-4">Ik wil {product.name} huren gedurende de periode: </h3>
        <DateRangePicker
          startDate={start} // momentPropTypes.momentObj or null,
          startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
          endDate={end} // momentPropTypes.momentObj or null,
          endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
          onDatesChange={({ startDate, endDate }) => {
            /* debugger
            if(start && end && startDate && !startDate.isSame(start)){
              this.setState({ start: startDate, end: null });
            } */
            this.setState({ start: startDate, end: endDate });
          }} // PropTypes.func.isRequired,
          focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
          onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
          displayFormat="D MMM"
          startDatePlaceholderText="Startdatum"
          endDatePlaceholderText="Einddatum"
          minimumNights={0}
          isDayBlocked={day => {
            if (day.isSame(new Date(), "day")) {
              return true;
            }
            if (start && !end) {
              let firstBlockedAfterStart;

              for (let m of blockedDays) {
                if (m.isAfter(start)) {
                  firstBlockedAfterStart = m;
                  break;
                }
              }

              if (firstBlockedAfterStart && day.isAfter(firstBlockedAfterStart)) {
                return true;
              }
            }

            let bl = blockedDays.find((d: Moment) => {
              return d.startOf("day").isSame(day.startOf("day"));
            });
            return bl !== undefined;
          }}
        />
        <div>
          <button
            className="button is-light"
            style={{
              float: "left",
            }}
            onClick={() => {
              this.setState({
                step: step - 1,
              });
            }}
          >
            Vorige Stap
          </button>
          <button
            className="button is-primary"
            onClick={() => {
              if (start !== null && end !== null) {
                this.setState({
                  step: step + 1,
                });
              } else {
                alert("Gelieve een start- en einddatum te selecteren.");
              }
            }}
          >
            Volgende Stap - Bevestiging
          </button>
        </div>
      </div>
    );
  }

  renderStep4(product: Product) {
    const {
      step,
      email,
      firstName,
      lastName,
      coupon,
      products,
      selectedServices,
      start,
      end,
      delivery,
      postal,
    } = this.state;
    const { productId } = this.props;

    if (productId) {
      const product = products.find(product => product.id === productId);

      if (product) {
        return (
          <div className="rent-content checkout">
            <h3 className="title is-4">Bevestig uw aanvraag</h3>
            <div className="box">
              <div>
                <h4 className="title is-5">Huur box</h4>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: "20px",
                  }}
                >
                  <p>{product.name}</p>
                  <p>{this.calculateRentPrice(product.rentprices) + ",- €"}</p>
                </div>
                {selectedServices.length > 0 && <h4 className="title is-5">Aanvullende Diensten</h4>}
                <ul>
                  {selectedServices.map(service => {
                    return (
                      <div
                        key={service.id}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          marginBottom: "10px",
                        }}
                      >
                        <p>
                          {service.amount && <b>{service.amount + "x "}</b>}
                          {service.label}
                        </p>
                        <p>
                          {service.amount && <b>{service.amount + "x "}</b>}
                          {service.price + ",- €"}
                        </p>
                      </div>
                    );
                  })}
                </ul>
              </div>
              <div
                style={{
                  marginBottom: "20px",
                }}
              >
                <h4 className="title is-5">Totaalprijs</h4>
                <span>{this.calculateTotalPrice(product.rentprices)}</span>
              </div>
              <div>
                <h4 className="title is-5">Periode</h4>
                {start && end && (
                  <p>
                    {"Van " +
                      moment(start)
                        .format("D MMMM YYYY")
                        .toString() +
                      " tot " +
                      moment(end)
                        .format("D MMMM YYYY")
                        .toString()}
                  </p>
                )}
              </div>
            </div>
            <div className="field">
              <label className="label" htmlFor="delivery-selector">
                Emailadres
              </label>
              <div className="control">
                <input
                  type="email"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" title="Ongeldig email-adres opgegeven."
                  className="input"
                  value={email}
                  onChange={e => this.setState({ email: e.target.value })}
                  placeholder="Vb. peter@bedrijf.be"
                />
              </div>
            </div>
            <div className="field">
              <label className="label" htmlFor="delivery-selector">
                Voornaam
              </label>
              <div className="control">
                <input
                  type="text"
                  className="input"
                  value={firstName}
                  onChange={e => this.setState({ firstName: e.target.value })}
                />
              </div>
            </div>
            <div className="field">
              <label className="label" htmlFor="delivery-selector">
                Achternaam
              </label>
              <div className="control">
                <input
                  type="text"
                  className="input"
                  value={lastName}
                  onChange={e => this.setState({ lastName: e.target.value })}
                />
              </div>
            </div>
            <div className="field">
              <label className="label" htmlFor="delivery-selector">
                Kortingscode (Optioneel)
              </label>
              <div className="control">
                <input
                  type="text"
                  className="input"
                  value={coupon}
                  onChange={e => this.setState({ coupon: e.target.value })}
                />
              </div>
            </div>
            <button
              className="button is-light"
              style={{
                float: "left",
              }}
              onClick={() => {
                this.setState({
                  step: step - 1,
                });
              }}
            >
              Vorige Stap
            </button>
            <button
              className="button is-primary"
              disabled={email === "" || firstName === "" || lastName === "" || !this.isValidEmail(email)}
              onClick={() => {
                finishRent(
                  email,
                  firstName,
                  lastName,
                  product,
                  selectedServices,
                  start
                    ? moment(start)
                        .format("D MMMM YYYY")
                        .toString()
                    : "",
                  end
                    ? moment(end)
                        .format("D MMMM YYYY")
                        .toString()
                    : "",
                  coupon,
                  delivery,
                  postal
                );

                this.setState({
                  showConfirmationModal: true,
                });
              }}
            >
              Verzend Aanvraag
            </button>
          </div>
        );
      }
    }
    return "";
  }

  render() {
    const { step, products, delivery, selectedServices, start, end, showConfirmationModal } = this.state;
    const { productId } = this.props;

    if (productId) {
      const product = products.find(product => product.id === productId);

      if (product) {
        const { name } = product;
        return (
          <Page title={<>Huur een Get-Outt Box</>}>
            <div className="rent columns">
              <div className="column is-6">
                <h3 className="title is-4">Huuraanvraag voor {product.name}</h3>
                <img src={product.imageUrls[0]} alt={product.name} />
                <Link to="/producten" className="button is-light">
                  Wijzig product
                </Link>
              </div>
              <div className="column">
                {step === 0 && this.renderStep1()}
                {step === 1 && this.renderStep2()}
                {step === 2 && this.renderStep3(product)}
                {step === 3 && this.renderStep4(product)}
              </div>
            </div>
            <hr />
            <div className="box">
              <h3 className="title is-4">Overzicht</h3>
              <div className="rent-overview">
                <div>
                  <h4 className="title is-6">Stap 1: Keuze Product</h4>
                  <p>{name}</p>
                  <Link to="/producten">Wijzig</Link>
                </div>
                {step > 1 && (
                  <div>
                    <h4 className="title is-6">Stap 2: Aanvullende Informatie</h4>
                    <p>{delivery}</p>
                    <br />
                    {selectedServices.length > 0 && (
                      <p>
                        <b>Diensten</b>
                      </p>
                    )}
                    <ul>
                      {selectedServices.map(service => {
                        return <li>{service.label}</li>;
                      })}
                    </ul>
                    <a
                      onClick={() => {
                        this.setState({ step: 1 });
                      }}
                    >
                      Wijzig
                    </a>
                  </div>
                )}
                {step > 2 && (
                  <div>
                    <h4 className="title is-6">Stap 3: Periode</h4>
                    {start && end && (
                      <p>
                        {"Van " +
                          moment(start)
                            .format("D MMMM YYYY")
                            .toString() +
                          " tot " +
                          moment(end)
                            .format("D MMMM YYYY")
                            .toString()}
                      </p>
                    )}
                    <a
                      onClick={() => {
                        this.setState({ step: 2 });
                      }}
                    >
                      Wijzig
                    </a>
                  </div>
                )}
              </div>
            </div>
            <Modal
              isActive={showConfirmationModal}
              close={() => {
                this.setState({
                  showConfirmationModal: false,
                });
                window.location.href = "/";
              }}
            >
              <div className="box">
                <h3 className="title is-4">Aanvraag voltooid!</h3>
                <p>
                  Bedankt voor uw aanvraag. <br /> Wij nemen spoedig contact met u op.
                </p>
                <a style={{ marginTop: "10px" }} href="/" className="button is-light">
                  Sluiten
                </a>
              </div>
            </Modal>
          </Page>
        );
      }
    }
    return (
      <Page title={<>Huur een Get-Outt Box</>}>
        <p>Gelieve een product te kiezen.</p>
        <Link to="/producten" className="button is-light">
          Kies Product
        </Link>
      </Page>
    );
  }
}

export default Rent;
