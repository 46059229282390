function isInt(number: number) {
  return number % 1 === 0;
}

function groupDigits(number: number) {
  number = Math.ceil(number);
  var str = number.toString().replace(".", ",");
  return str.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
}

export function format(price: number, vat: number) {
  if(price === 0) {
     return "Prijs op aanvraag";
  }
  return "€" + groupDigits(price * (1+vat/100));
}
