import { FunctionComponent, ReactElement } from "react";
import * as React from "react";
import "./Page.scss";
import getoutt from "./img/logo.png";

type Props = {
  title: string | ReactElement<any>;
  subTitle?: string | ReactElement<any>;
};

const Page: FunctionComponent<Props> = ({ title, children, subTitle }) => {
  return (
    <div className="page">
      {subTitle && (
        <section className="hero">
          <div className="hero-body">
            <div className="container">
              <div>
                <h1 className="title">{title}</h1>
                {subTitle && <h2 className="subtitle">{subTitle}</h2>}
              </div>
            </div>
          </div>
        </section>
      )}
      <div className="container">{children}</div>
    </div>
  );
};

export default Page;
