const TOGGLE_CART = "TOGGLE_CART";
export function toggleCart() {
  return {
    type: TOGGLE_CART,
  };
}

type State = {};

export const data = (state: State = {}, action: any) => {
  switch (action.type) {
    default:
      return state;
  }
};
