import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import * as React from "react";
import { render } from "react-dom";
import "./index.scss";
import App from "./App";
import { unregister } from "./serviceWorker";
import { Data } from "./Data";

const data = new Data();

render(<App data={data} />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// noinspection JSIgnoredPromiseFromCall
unregister();
